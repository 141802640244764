<template>
	<v-app-bar
		id="default-app-bar"
		app
		class="v-bar--underline"
		color="#ffffff"
		:clipped-left="$vuetify.rtl"
		:clipped-right="!$vuetify.rtl"
		height="80"
		flat
		elevation="6"
	>
		<v-row class="centered">
			<v-col cols="6">
				<router-link :to="hpLink">
					<figure><img id="logo" :src="logoImage" /></figure>
				</router-link>
			</v-col>
			<v-col cols="6 text-right">
				<DropdownUser></DropdownUser>
			</v-col>
		</v-row>
	</v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DropdownUser from "@/components/DropdownUser.vue";

export default {
	name: "AdminDefaultAppBar",
	components: {
		DropdownUser
	},
	computed: {
		...mapGetters(["organization"]),

		logoImage() {
			if (this.$store.state.organization.logo?.file) {
				return this.$store.state.organization.logo?.file?.public_url;
			} else {
				return require("@/assets/adminlogo.png");
			}
		},
		organizationId() {
			return this.organization?.id;
		},
		hpLink() {
			if (!!this.organizationId === false) return "";
			return `/?organizationId=${this.organizationId}`;
		}
	},
	methods: {
		...mapActions(["logout"])
	}
};
</script>

<style lang="scss">
#default-app-bar {
	background-color: #2f91ae !important;
	box-shadow: none !important;
	height: 100px !important;
	padding: 10px 0;
	border-bottom-right-radius: 60px;

	.primary-contrast-background {
		background-color: #fff !important;
		.text-h5 {
			color: #2f91ae !important;
		}
	}
}
</style>
